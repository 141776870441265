

@font-face {
  font-family: "AvenirNext-Bold";
  src: url('./fonts/AvenirNext-Bold.ttf');
}
@font-face {
  font-family: "AvenirNext-DemiBold";
  src: url('./fonts/AvenirNext-DemiBold.ttf');
}
@font-face {
  font-family: "AvenirNext-Medium";
  src: url('./fonts/AvenirNext-Medium.ttf');
}
@font-face {
  font-family: "AvenirNext-Regular";
  src: url('./fonts/AvenirNext-Regular.ttf');
}
@font-face {
  font-family: "Helvetica-Neue-Condensed-Black";
  src: url('./fonts/Helvetica-Neue-Condensed-Black.ttf');
}
